<script>
/* eslint-disable */
import LeilaoMixin from "components/leiloes/components/LeilaoMixin"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"

export default {
  name: 'LeilaoGravações',
  inject: ['page'],
  mixins: [LeilaoMixin],
  components: {ECol, ERow},
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Gravações do Leilão',
    name: 'LeilaoGravacoes'
  }
}
</script>

<template>
  <div class="">
    <div class="content">
      <header>
        <e-row mr>
          <e-col><h2 class="component-title">Gravações do leilão</h2></e-col>
          <e-col class="text-right">
          </e-col>
        </e-row>
      </header>

      <div class="body">
        Em breve você poderá solicitar gravações dos leilões.
      </div>
    </div>
  </div>
</template>
